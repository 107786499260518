<template>
  <div class="home-container">
    <div class="home-banner p-4">
      <img
        v-if="isImageLoaded"
        :src="imageUrl + organization + '.jpg'"
        height="50"
        class="ml-4"
        @error="handleImageError"
      />
      <a-typography-title v-else class="text-white ml-4" :level="2">
        Organization Summary
      </a-typography-title>
    </div>
    <div class="cards-container p-lg-4 p-xl-4 p-md-3 p-sm-4 p-2">
      <div class="cards-wrapper px-lg-4 px-xl-4 px-md-3 px-sm-4 px-2">
        <a-row class="pb-4" :gutter="[24, 24]">
          <a-col
            v-for="device in sortedDevices"
            :key="device.Serial_number"
            :xs="24"
            :sm="24"
            :md="24"
            :lg="12"
            :xl="8"
            :xxl="8"
          >
            <work-station-card
              :device="device"
              :active-devices="activeDevices"
              :id-to-task-name-mapping="idToTaskNameMapping"
              :id-to-device-serial-no-mapping="idToDeviceSerialNoMapping"
              :active-devices-settings="activeDevicesSettings"
              :active-devices-status="devicesStats"
            />
          </a-col>
        </a-row>
        <div
          v-if="sortedDevices.length === 0"
          style="height: 70vh"
          class="no-active-device"
        >
          <!-- <a-result status="404" title="No Active Device Found"> </a-result> -->
          <laptop-outlined class="text-secondary" />

          <a-typography-title :level="3" class="mt-2 text-secondary">
            No Active Device(s) Found
          </a-typography-title>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import WorkStationCard from './WorkStationCard.vue';
import DeviceService from '@/services/device.js';
import TaskService from '@/services/tasks.js';
import { LaptopOutlined } from '@ant-design/icons-vue';
import { mapActions, mapGetters } from 'vuex';
import { useActiveDevices } from '../RecordData/composables/useActiveDevices';
import { useDeviceSettings } from '../RecordData/composables/useDeviceSettings';
import { inject, ref, watch } from 'vue';
import { dateFormat } from 'src/config/date-format-config.js';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default {
  components: {
    WorkStationCard,
    LaptopOutlined
  },

  data() {
    return {
      loading: false,
      deviceList: [],
      // activeDevices: {},
      // isPolled: false,
      // activeDevicesPolling: null,
      tasksList: [],
      idToTaskNameMapping: {},
      idToDeviceSerialNoMapping: {},
      activeDevicesSettings: [],
      deviceStatsPolling: null,
      devicesStats: {},
      isImageLoaded: true,
      imageUrl:
        'https://retrocausal-video-thumbnails.s3.us-east-2.amazonaws.com/logos/'
    };
  },

  setup() {
    const toast = inject('toast');
    const deviceRef = ref({});
    const { activeDevices: curentActiveDevices } = useActiveDevices();

    const { devicesSettingsDict, devicesStatusDict } = useDeviceSettings(toast);

    watch(
      () => curentActiveDevices.value,
      (dict, _) => {
        if (!Object.keys(dict).length) return;
        deviceRef.value = {};
        Object.entries(dict).forEach(([key, value]) => {
          deviceRef.value[value?.Serial_number] = value;
        });
      }
    );

    return {
      // activeDevices,
      // activeDevicesList,
      devicesSettingsDict,
      devicesStatusDict,
      activeDevices: deviceRef
    };
  },

  computed: {
    ...mapGetters(['organization', 'devices']),
    sortedDevices() {
      if (this.deviceList.length) {
        const [active, starred, inactive] = this.sortDevices(
          this.deviceList,
          this.sortDevicesCallback
        );
        this.deviceList.map(device => {
          if (!device.last_Active.endsWith('ago')) {
            if (device.last_Active.endsWith('UTC'))
              device.last_Active = dayjs(
                new Date(device.last_Active).toISOString()
              ).fromNow();
          }
          return device;
          // device.last_Active = dayjs(
          //   new Date(device.last_Active).toISOString()
          // ).format('DD/MM/YYYY HH:mm:ss');

          // if (device.last_Active.includes('UTC')) {
          //   const originalDate = new Date(
          //     device.last_Active.replace(' UTC', '')
          //   );
          // device.last_Active = this.formatDateWithCurrentTime(originalDate);
          // }
        });
        this.sortActiveDevicesByDisplayName(active);
        return [...active, ...starred, ...inactive];
      } else {
        return [];
      }
    }
  },

  watch: {
    devices: {
      handler(val) {
        this.deviceList = [...val]
      },
      deep: true,
    },
    deviceList(list) {
      if (!list.length) return;
      this.idToDeviceSerialNoMapping = this.getIdToAttributeMapOfList(
        list,
        'Serial_number'
      );
    },
    tasksList(tasks) {
      if (!tasks.length) return;
      this.idToTaskNameMapping = this.getIdToAttributeMapOfList(
        tasks,
        'taskName'
      );
    },
    activeDevices(dict) {
      if (dict && Object.keys(dict)?.length) {
        if (!this.deviceStatsPolling) this.setDeviceStatsInterval();
      } else if (this.deviceStatsPolling) {
        clearInterval(this.deviceStatsPolling);
        this.deviceStatsPolling = null;
      }
    }
  },

  async created() {
    await this.checkPermissionsState();
    this.getAllDevices();
     this.getTasksList();
     this.getActiveDevicesStats();
  },

  beforeUnmount() {
    if (this.deviceStatsPolling) clearInterval(this.deviceStatsPolling);
  },

  methods: {
    ...mapActions(['getOrgDevices']),
    async checkPermissionsState() {
      try {
        const microphoneStatus = await navigator.permissions.query({ name: 'microphone' });
        const cameraStatus = await navigator.permissions.query({ name: 'camera' });

        if (microphoneStatus.state === 'denied' || microphoneStatus.state === 'prompt' ||
            cameraStatus.state === 'denied' || cameraStatus.state === 'prompt') {
          this.requestPermissions();
        } else {
        }
      } catch (error) {
        console.log('Error checking media permissions:', error);
      }
    },
    async requestPermissions() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        console.log('Permissions granted:', stream);
        // Stop the stream to avoid using resources unnecessarily
        stream.getTracks().forEach(track => track.stop());
        await this.checkPermissionsState(); // Update permissions after requesting
      } catch (err) {
        console.log('Permissions denied:', err);
      }
    },
    formatDateWithCurrentTime(date) {
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        timeZoneName: 'short'
      };
      const formattedDate = new Intl.DateTimeFormat('en-US', options).format(
        date
      );
      return formattedDate.replace(' PM', '').replace(' GMT+5', '');
    },

    sortDevicesCallback(serialNumber, starred) {
      if (this.activeDevices && this.activeDevices[serialNumber]) return 0;
      if (starred === true) return 1;
      else return 2;
    },

    sortDevices(devices, checkCondition) {
      return devices.reduce(
        (acc, e) => {
          acc[checkCondition(e.Serial_number, e.starred)].push(e);
          return acc;
        },
        [[], [], []]
      );
    },

    getIdToAttributeMapOfList(list, attributeName) {
      return list.reduce((res, el) => {
        res[el.id] = el[attributeName];
        return res;
      }, {});
    },

    async getAllDevices() {
      this.loading = true
      if(this.devices.length == 0){
        console.log('No devices, lets call api')
        await this.getOrgDevices(this.organization)
      }else{
        this.deviceList = [...this.devices]
        // this.startLoadingMoreDevices()
      }
      this.loading = false
    },

    startLoadingMoreDevices() {
      this.intervalId = setInterval(() => {
        this.loadMoreDevices();
      }, 2000);
    },

    async loadMoreDevices() {
      if (this.deviceList.length >= this.devices.length) {
        return;
      }

      this.isLoadingMore = true;

      // Simulate async data fetch
      await new Promise((resolve) => setTimeout(resolve, 1000));

      const nextBatch = this.devices.slice(this.deviceList.length, this.deviceList.length + 10);
      this.deviceList = this.deviceList.concat(nextBatch)
      this.isLoadingMore = false;
    },

    async getTasksList() {
      const [error, data] = await TaskService.fetchTasks(false);
      if (error) return;
      this.tasksList = data;
    },

    async getActiveDevicesStats() {
      const currentDate = `${dayjs(new Date().toISOString()).format(
        dateFormat
      )}`;
      const [error, data] = await DeviceService.getDeviecesStats(false, {
        current_date: currentDate
      });
      if (error || !data.length) return;

      const stats = data.reduce((res, device) => {
        res[`${device.serial_number}${device.task_id}`] = { ...device.stats };
        return res;
      }, {});
      this.devicesStats = stats;
    },

    setDeviceStatsInterval() {
      this.deviceStatsPolling = setInterval(() => {
        this.getActiveDevicesStats();
      }, 10000);
    },

    sortActiveDevicesByDisplayName(active) {
      active.sort((a, b) => {
        const firstDevice = a.display_name?.toLowerCase();
        const secondDevice = b.display_name?.toLowerCase();
        if (firstDevice === null && secondDevice === null) return 0;
        if (firstDevice === null) return 1;
        if (secondDevice === null) return -1;
        if (firstDevice < secondDevice) return -1;
        if (firstDevice > secondDevice) return 1;
        return 0;
      });
    },

    handleImageError() {
      this.isImageLoaded = false;
    }
    // async getActiveDevices() {
    //   console.log('getActive devces')
    //   if (this.isPolled) return;
    //   this.isPolled = true;
    //   const [error, data] = await DeviceService.getActiveDevicesOfOrg();
    //   if (error) return;
    //   this.isPolled = false;
    //   this.activeDevices = Array.isArray(data) ? [...data] : Object.keys(data);
    // },

    // async getActiveDevicesSettings() {
    //   if (!this.activeDevices.length) return;
    //   const devices = JSON.stringify(this.activeDevices);
    //   const [error, data] = await DeviceService.getSettingsOfActiveDevices(
    //     false,
    //     {
    //       devices: devices
    //     }
    //   );
    //   if (error) return;
    //   this.activeDevicesSettings = data;
    //   await this.getActiveDevicesStatuses(devices);
    // },

    // async getActiveDevicesStatuses(devices) {
    //   const payload = { Serial_number: devices };
    //   const [error, data] = await DeviceService.getStatusOfActiveDevices(
    //     false,
    //     payload
    //   );
    //   if (error) return;
    //   this.devicesStats = data;
    // }
  }
};
</script>
<style>
.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-banner {
  width: 100%;
  height: 15vh;
  background-color: #1890ff;
}

.cards-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: translateY(-60px);
  position: relative;
  z-index: 99;
}

.cards-wrapper {
  height: 100%;
  overflow-y: auto;
}

.cards-wrapper::-webkit-scrollbar-track {
  background-color: transparent;
}

.no-active-device {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-active-device > .anticon-laptop {
  font-size: 3em;
}

@media only screen and (max-width: 576px) {
  .cards-container {
    transform: translateY(-40px);
  }
}
</style>
